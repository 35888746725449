import { useErrorBoundary } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

export class FormValidationError extends Error {}

/**
 * useSWRMutationForm is a hook that facilitates collecting data with useForm to
 * modify a resource via useSWRMutation.
 *
 * It is primarily responsible for configuring
 * a form with validation rules that are informed by both the client and the server.
 *
 * It assumes that all of your input components are configured to be managed by an
 * instance of useForm.
 *
 * @param {Function} mutationHook
 * A useSWRMutation hook. For more information, see: https://react-hook-form.com/docs
 *
 * Make sure to merge the options that useSWRMutationForm provides to the hook.
 * Example: (theirOptions) => useSWRMutation(key, fetcher, { ...ourOptions, ...theirOptions })
 *
 * @param {Function} [formHook]
 * An optional useForm hook. For more information, see: https://swr.vercel.app/docs
 *
 * You can pass this yourself if you want to provide options
 * to useForm, just make sure to merge the options that useSWRMutationForm provides to
 * the hook.
 * Example: (theirOptions) => useForm({ ...ourOptions, ...theirOptions })
 *
 * If not present, useForm will be instantiated for you with our default options.
 *
 * @param {Function} [onSubmit]
 * An optional function that receives the value of the form and returns the payload
 * to send to the mutation.
 * Example: (form) => ({ name: form.name.capitalize() })
 *
 * If not present, the identity function will be used.
 *
 * @param {Object} [errorSchema]
 * An optional schema object that describes one or more mutation errors that the form should be
 * aware of and what to do with them. In this object, the keys correspond to `systemError` values
 * present in platform generic error payloads, and the values are `validation`
 * objects like those present in platform validation error payloads.
 *
 * For instance, let's say the API returns an error like this:
 * {
 *   systemError: 'INCORRECT_PASSWORD'
 *   // ...
 * }
 *
 *
 * If you want this error to trigger the invalid state of fields in the form,
 * you would pass an errorSchema like:
 * {
 *   INCORRECT_PASSWORD: {
 *     email: [''], // Field named `email` will be invalid but have no helper text
 *     password: ['Please enter a correct password'], // Field named `password` will be
 *                                                    // invalid with supplied helper text
 *   }
 * }
 *
 * Additionally, the string "INCORRECT_PASSWORD" will be available in the `error` property of
 * the return object in the event that you need to render something based on that enum. If this
 * is all you need, you can supply an empty object like:
 * {
 *  INCORRECT_PASSWORD: {}
 * }
 *
 * @param {Function} [useUnhandledError]
 * A hook that tells `useSWRMutationForm` what to do with any unhandled error value
 * that your function may throw. Defaults to `useErrorToBoundary`
 *
 *
 * @returns {Object} mutationForm
 *
 * @returns {Boolean} mutationForm.isSubmitting
 * Whether the form is currently submitting
 *
 * @returns {Function} mutationForm.handleSubmit
 * A function that initiates the form submission.
 * Pass this as the `onSubmit` event handler of a form element.
 *
 * @returns {String|undefined} mutationForm.error
 * If present, a string representing the root error held by form.
 */

// Thows to the nearest error boundary
export const useErrorToBoundary = () => {
  const { showBoundary } = useErrorBoundary();
  return {
    handleUnhandledError: showBoundary,
  };
};

// An unhandled error will be stored to the return value's error property as 'UNHANDLED_ERROR'.
export const useUnhandledErrorToState = () => ({
  handleUnhandledError: (err, form) => form.setError('root', { _type: 'UNHANDLED_ERROR' }),
});

// Opens notistack's snackbar & closes exisiting snacksbars on handleSubmitAndError
export const useErrorToSnackbar = () => ({
  handleUnhandledError: () => enqueueSnackbar('Currently experiencing issues processing your request.', { variant: 'error' }),
  resetError: closeSnackbar,
});

const useSWRMutationForm = ({
  useMutation: mutationHook,
  useForm: formHook = useForm,
  onSubmit = (x) => x,
  errorSchema,
  formOptions,
  useUnhandledError = useErrorToBoundary,
  showBoundaryOnAuthErrors = true,
}) => {
  const { trigger, isMutating } = mutationHook();
  const { handleSubmit, ...form } = formHook({
    criteriaMode: 'all',
    mode: 'onTouched',
    ...formOptions,
  });
  const { showBoundary } = useErrorBoundary();
  const { handleUnhandledError, resetError } = useUnhandledError();

  // Pull the server-side validation out of an error response
  // whether it is a systemError or field-level validation
  const getValidation = useCallback((e) => {
    const data = e?.response?.data;

    const validation = errorSchema?.[data?.systemError];
    if (validation) {
      // A known system error that we are providing our own
      // validation rules for
      return {
        type: data.systemError,
        errors: validation,
      };
    }

    if (Object.keys(data?.validation ?? {}).length) {
      // A validation error from the platform
      return {
        type: data.systemError,
        errors: data.validation,
      };
    }

    if (data?.errors && Object.keys(data?.errors).length) {
      // A validation error from CAPS
      return {
        type: 'GENERIC_CAPS_ERROR',
        errors: data.errors,
      };
    }

    if (e instanceof FormValidationError) {
      return {
        type: e.message,
        errors: [],
      };
    }

    return null;
  }, [errorSchema]);

  const handleError = (err) => {
    // Throw auth errors to boundary
    if (showBoundaryOnAuthErrors && [401, 403].includes(err?.response?.status)) {
      showBoundary(err);
      return;
    }

    const validation = getValidation(err);
    if (!validation) {
      handleUnhandledError(err, form);
      return;
    }

    const { type, errors } = validation;

    // An error with no validation
    form.setError('root', { _type: type });

    // An error with field-level validation
    Object.entries(errors).forEach(([field, [message]]) => {
      form.setError(`root.${field}`, { message });
    });

    // Focus the first invalid input
    //
    // Note: This relies on the assumption that the keys of the object are in order,
    // which is not guaranteed, but seems to be stable enough for our purposes.
    // Will unit test, so we'll know if this assumption ever breaks.
    const orderedFieldNames = Object.keys(form.getValues());
    const [firstInvalidInput] = orderedFieldNames.filter((input) => errors[input]);
    form.setFocus(firstInvalidInput);
  };

  const handleSubmitAndError = async () => {
    try {
      resetError?.();
      await trigger(await onSubmit(form.getValues()));
    } catch (err) {
      handleError(err);
    }
  };

  return {
    ...form,
    handleSubmit: (e) => handleSubmit(handleSubmitAndError)(e),
    isSubmitting: isMutating,
    // eslint-disable-next-line no-underscore-dangle
    error: form.formState.errors?.root?._type,
  };
};

export default useSWRMutationForm;
